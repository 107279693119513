import ResultsProgress from 'components/ResultsProgress';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import Logo from 'assets/icons/logo.svg';
import Check from 'assets/icons/green-checkmark.svg';
import { DynamicImage, PrimaryButton } from 'components';
import { useRouter } from 'apis/history';

const ResultsInfo: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsInfo');
  const user = useSelector((state: AppState) => state.user);
  const { goToResultsAbout } = useRouter();
  const handleClick = () => {
    setLoading(true);
    goToResultsAbout();
  };

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  return (
    <Container>
      <Inner>
        <LogoStyled />
        <ResultsProgress
          currentStep={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
        <Card>
          <Title>{data?.title}</Title>
          <Subtitle>{data?.subtitle}</Subtitle>
          <StyledImg src={data?.img} alt="" />
          <List>
            {data?.list.map((item: string, index: number) => (
              <ListBox>
                <Svg>
                  <Check />
                </Svg>
                <ListItem
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </ListBox>
            ))}
          </List>

          <StyledButton onClick={handleClick} loading={loading}>
            {data?.buttonText}
          </StyledButton>
        </Card>
      </Inner>
    </Container>
  );
};

export default ResultsInfo;

const Container = styled.section`
  background: #ffe7de;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 0 1rem 2rem;
  }
  min-height: 100vh;
`;

const Inner = styled.section`
  max-width: 31.25rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoStyled = styled(Logo)`
  margin: 1.25rem auto;
`;

const Card = styled.section`
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;
  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding-top: 0.75rem;
`;

const StyledImg = styled(DynamicImage)`
  margin: 0.8rem auto 1.2rem;
  height: 18.10694rem;
  @media ${tablet} {
    height: 16.5625rem;
    margin: 0 auto 0.5rem;
  }
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;

const List = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

const ListBox = styled.section`
  display: flex;
  align-items: flex-start;
  gap: 0.5625rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #28293d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

const Svg = styled.section`
  width: 1.625rem;
  height: 1.625rem;
  svg {
    width: 1.625rem;
    height: 1.625rem;
  }
`;
